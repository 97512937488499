import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsService } from '../cms/cms.service';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  isLoading = false;
  submitted = false;
  showForm = true;
  loading = false;
  cmsResult: any;

  public errorMessage: any = {};
  public successMessage: any = {};

  contactForm: FormGroup;
  postJson: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cmsService: CmsService,
    private contactService: ContactService
  ) {
    this.showForm = true;
    this.getCmsPageBySlug();
  }

  ngOnInit(): void {
    this.setControll();
  }

  setControll() {
    this.contactForm = this.formBuilder.group({
      email: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone_number: ['', Validators.required],
      address_line_1: ['', Validators.required],
      address_line_2: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zip_code: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.contactForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    this.isLoading = true;

    this.contactService.ContactUs(this.contactForm.value).subscribe(data => {
      this.isLoading = false;
      this.showForm = false;
      const details: any = data;
      if (details.status === "success") {
        this.errorMessage.message = 'Contact Us Form Submitted successfully!';
        setTimeout(() => {
          this.errorMessage.message = "";
        }, 3000);
      }
    });
  }

  public getCmsPageBySlug(): void {
    this.isLoading = true;
    this.cmsService.getCmsPageBySlug('contact-us-info').subscribe((cmsResult) => {
      this.cmsResult = cmsResult;
      this.cmsResult = this.cmsResult.result;
      this.isLoading = false;
    });
  }
}
