import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(private _api: ApiService, private _token: TokenStorageService) {
    this.userSubject = new BehaviorSubject<any>(this._token.getUser());
    this.user = this.userSubject.asObservable();
  }

  getUser() {
    console.log(this.userSubject);
    console.log(this.userSubject.value);
    return this.userSubject.value;
  }

  login(credentials: any): Observable<any> {
    return this._api
      .postTypeRequest('login', {
        email: credentials.email,
        password: credentials.password,
        role_id: credentials.role_id
      })
      .pipe(
        map((res: any) => {
          let user = {
            email: credentials.email,
            token: res.result.access_token,
          };
          this._token.setToken(res.result.access_token);
          this._token.setUser(res.result);
          console.log(res);
          this.userSubject.next(user);
          return user;
        })
      );
  }

  register(user: any): Observable<any> {
    return this._api.postTypeRequest('register', {
      name: user.fullName,
      email: user.email,
      password: user.password,
      phone_number: user.phoneNumber,
    });
  }

  logout() {
    this._token.clearStorage();
    this.userSubject.next(null);
  }
}
