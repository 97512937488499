import { PLATFORM_ID, APP_ID, Inject, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigurationService } from '../services/configuration.service';
import { Cms } from './cms.model';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  isBrowser: boolean;
  result: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,
    private httpService: HttpClient,
    private myService: ConfigurationService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public getCmsPageBySlug(slug: any): Observable<Cms> {
    if (this.isBrowser) {
      try {
        const access_token = localStorage.getItem('access_token');
        const header = new HttpHeaders().set(
          'Authorization',
          'Bearer ' + access_token
        );
        return this.httpService.get<Cms>(
          this.myService.constant.apiUrl +
          'pages/'+slug,
          { headers: header }
        );
      } catch (error) {
        console.error(error);
      }
    }
    return this.result;
  }

  public getAllSection(slug: any): Observable<Cms> {
    if (this.isBrowser) {
      try {
        const access_token = localStorage.getItem('access_token');
        const header = new HttpHeaders().set(
          'Authorization',
          'Bearer ' + access_token
        );
        return this.httpService.get<Cms>(
          this.myService.constant.apiUrl +
          'sections/'+slug,
          { headers: header }
        );
      } catch (error) {
        console.error(error);
      }
    }
    return this.result;
  }

}
