import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public constant: any = {};
  public apiUrl = environment.apiUrl;
  public oauthCredential = environment.oauthCredential;
  public AESEncrypt = environment.AESEncrypt;
  constructor() {
    this.constant = {
      apiUrl: this.apiUrl,
      oauthCredential: this.oauthCredential,
      AESEncrypt: this.AESEncrypt,
    };
  }
}
