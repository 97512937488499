<header id="header">
  <nav
    id="main-nav"
    class="navbar navbar-default navbar-fixed-top"
    role="banner"
  >
    <div class="container">
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle"
          data-toggle="collapse"
          data-target=".navbar-collapse"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="index.html">
          <img
            src="./assets/images/logo.png"
            alt="Z4QC"
            style="max-width: 100px"
          />
        </a>
      </div>

      <div class="collapse navbar-collapse navbar-right">
        <ul class="nav navbar-nav">
          <li class="scroll active"><a href="#home">Home</a></li>
          <li class="scroll"><a href="#products">Products</a></li>
          <li class="scroll"><a href="#services">Services</a></li>
          <li class="scroll"><a href="#about">About</a></li>
          <li class="scroll"><a href="#team">Team</a></li>
          <li class="scroll"><a href="#pricing">Pricing</a></li>
          <li class="scroll"><a href="#contact-us">Contact</a></li>
        </ul>
      </div>
    </div>
    <!--/.container-->
  </nav>
  <!--/nav-->
</header>
<!--/header-->
