<!--Section: Contact v.2-->
<div class="container">
    <section class="mt-4 pt-5">
        <!--Section heading-->
        <h2 class="h1-responsive font-weight-bold text-center my-4" *ngIf="showForm">Contact us</h2>
        <h2 class="h1-responsive font-weight-bold text-center my-4" *ngIf="!showForm">Thanks</h2>

        <!--Section description-->
        <p *ngIf="showForm" class="text-center w-responsive mx-auto mb-5">
            Do you have any questions? Please do not
            hesitate to contact us
            directly. Our team will come back to you within
            a matter of hours to help you.
        </p>
        <div class="mb-5 text-center">{{this.errorMessage.message}}</div>
        <div class="row">
            <!--Grid column-->
            <div class="col-md-9 mb-md-0 mb-5">
                <form  *ngIf="showForm" [formGroup]="contactForm" autocomplete="off" (ngSubmit)="onSubmit()">
                    <fieldset class="scheduler-border mt-3">
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">Email ID</label>
                            <div class="col-md-8">
                                <input type="email" class="form-control" required formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email ID is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">First Name</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" required formControlName="first_name"
                                    [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" />
                                <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.first_name.errors.required">First Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">Last Name</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" required formControlName="last_name"
                                    [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                                <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">Phone Number</label>
                            <div class="col-md-8">
                                <input type="tel" class="form-control" required formControlName="phone_number"
                                    [ngClass]="{
                            'is-invalid': submitted && f.phone_number.errors
                          }" />
                                <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                                    <div *ngIf="f.phone_number.errors.required">
                                        Phone Number is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">Address Line 1:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" required formControlName="address_line_1"
                                    [ngClass]="{ 'is-invalid': submitted && f.address_line_1.errors }" />
                                <div *ngIf="submitted && f.address_line_1.errors" class="invalid-feedback">
                                    <div *ngIf="f.address_line_1.errors.required">
                                        Address Line 1 is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">Address Line 2:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" required formControlName="address_line_2"
                                    [ngClass]="{ 'is-invalid': submitted && f.address_line_2.errors }" />
                                <div *ngIf="submitted && f.address_line_2.errors" class="invalid-feedback">
                                    <div *ngIf="f.address_line_2.errors.required">
                                        Address Line 2 is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">City:</label>
                            <div class="col-md-8">
                                <input type="tel" class="form-control" required formControlName="city"
                                    [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                    <div *ngIf="f.city.errors.required">City is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">State:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" required formControlName="state"
                                    [ngClass]="{ 'is-invalid': submitted && f.state.errors }" />
                                <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                    <div *ngIf="f.state.errors.required">State is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">Country: </label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" required formControlName="country"
                                    [ngClass]="{ 'is-invalid': submitted && f.country.errors }" />
                                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                    <div *ngIf="f.country.errors.required">Country is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mt-3 align-items-center">
                            <label class="col-md-4 text-md-right text-right pr-5">Zip Code : </label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" required formControlName="zip_code"
                                    [ngClass]="{ 'is-invalid': submitted && f.zip_code.errors }" />
                                <div *ngIf="submitted && f.zip_code.errors" class="invalid-feedback">
                                    <div *ngIf="f.zip_code.errors.required">Zip Code is required</div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div class="mt-3 justify-content-center d-flex">
                        <button type="submit" [disabled]="isLoading" class="btn btn-success px-3 mb-3 mr-3">
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
                            Save
                        </button>
                        <button type="submit" class="btn btn-light px-3 mb-3">Cancel</button>
                    </div>
                </form>
                <div class="status"></div>
            </div>
            <!--Grid column-->
            <!--Grid column-->
            <div class="col-md-3 text-center bg-light mb-3">
                <div class="mt-4 p-3 border border-top-0  border-left-0  border-right-0">{{cmsResult.page_title}} :</div>
                <div [innerHtml]=cmsResult.content></div>
            </div>
            <!--Grid column-->
        </div>
    </section>
    <!--Section: Contact v.2-->
</div>