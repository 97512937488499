import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { LoginComponent } from './auth/components/login/login.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { ProductComponent } from './product/product.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { ProductListComponent } from './product-list/product-list.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'product',
    component: ProductListComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'product/:id',
    component: ProductComponent
  },
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'order-history',
    component: OrderHistoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./events/events.module').then(
        (mod) => mod.EventsModule
      ),
    data: { showHeader: false, showFooter: true, showSearch: true },
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./blog/blog.module').then(
        (mod) => mod.BlogModule
      ),
    data: { showHeader: false, showFooter: true, showSearch: true },
  },
  {
    path: '',
    loadChildren: () =>
      import('./cms/cms.module').then(
        (mod) => mod.CmsModule
      ),
    data: { showHeader: false, showFooter: true, showSearch: true },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
